import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";

import styles from "./OrderForm.module.css";
import { emailRgx, encode } from "../utils/formUtils";
import {
  OrderStateContext,
  OrderDispatchContext,
} from "../context/order-context";

function OrderForm() {
  const dispatch = useContext(OrderDispatchContext);
  const { order } = useContext(OrderStateContext);
  const [orderText, setOrderText] = useState("");

  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });

  useEffect(() => {
    let text = "";

    order.forEach((item) => {
      if (item.qty > 0) {
        text += `#${item.code} - ${item.name} x ${item.qty}\n`;
      }
    });

    setOrderText(text);
  }, [order]);

  const onSubmit = async (data) => {
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "order", ...data }),
      });

      dispatch({ type: "SEND_ORDER_SUCCESS" });
      navigate("/success", {
        state: { data },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <form
      className={styles.form}
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="order"
      onSubmit={handleSubmit(onSubmit)}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="order" />

      <div className={styles.formInfo}>
        <h2>Vaši podaci</h2>
        <p>Sva polja su obavezna osim broja telefona.</p>
      </div>

      <div className={styles.formGrid}>
        <label className={styles.email}>
          Email
          <input
            type="text"
            name="email"
            id="email"
            ref={register({
              required: "Ovo polje je obavezno",
              pattern: { value: emailRgx, message: "Invalid email" },
            })}
          />
          {errors.email && (
            <p className={styles.errorMsg}>{errors.email.message}</p>
          )}
        </label>

        <label className={styles.name}>
          Ime i prezime
          <input
            type="text"
            name="name"
            id="name"
            ref={register({ required: "Ovo polje je obavezno" })}
          />
        </label>
        <label className={styles.phone}>
          Broj telefona (nije obavezno)
          <input type="tel" name="phone" id="phone" ref={register} />
          <p>Kako bismo Vas lakše kontaktirali</p>
        </label>

        <label className={styles.orderNumber}>
          Broj narudžbe
          <input
            type="text"
            name="order-number"
            id="order-number"
            ref={register({ required: "Ovo polje je obavezno" })}
          />
          {errors["order-number"] && (
            <p className={styles.errorMsg}>Ovo polje je obavezno</p>
          )}
        </label>

        <label className={styles.company}>
          Naziv ustanove ili tvrtke
          <input
            type="text"
            name="company"
            id="company"
            ref={register({ required: "Ovo polje je obavezno" })}
          />
          {errors.company && (
            <p className={styles.errorMsg}>Ovo polje je obavezno</p>
          )}
        </label>

        <label className={styles.companyId}>
          OIB
          <input
            type="text"
            name="company-id"
            id="company-id"
            ref={register({ required: "Ovo polje je obavezno" })}
          />
          {errors["company-id"] && (
            <p className={styles.errorMsg}>Ovo polje je obavezno</p>
          )}
        </label>

        <label className={styles.address}>
          Adresa (Ulica i broj)
          <input
            type="text"
            name="address"
            id="address"
            ref={register({ required: "Ovo polje je obavezno" })}
          />
          {errors.address && (
            <p className={styles.errorMsg}>Ovo polje je obavezno</p>
          )}
        </label>

        <label className={styles.city}>
          Grad
          <input
            type="text"
            name="city"
            id="city"
            ref={register({ required: "Ovo polje je obavezno" })}
          />
          {errors.city && (
            <p className={styles.errorMsg}>Ovo polje je obavezno</p>
          )}
        </label>
        <label className={styles.zip}>
          Poštanski broj
          <input
            type="tel"
            name="zip"
            id="zip"
            ref={register({
              required: "Ovo polje je obavezno",
              maxLength: { value: 5, message: "Najviše 5 znamenaka" },
              pattern: { value: /\d/, message: "Najviše 5 znamenaka" },
            })}
          />
          {errors.zip && (
            <p className={styles.errorMsg}>{errors.zip.message}</p>
          )}
        </label>
        <label className={styles.country}>
          Država
          <input
            type="text"
            name="country"
            id="country"
            value="Hrvatska"
            disabled
            readOnly
          />
        </label>
      </div>

      <textarea
        id="order-list"
        name="order-list"
        className={styles.listForEmail}
        value={orderText}
        ref={register}
        readOnly
      />

      <button type="submit">Pošalji upit za ponudu</button>
    </form>
  );
}

export default OrderForm;
