import React, { useContext, useEffect, useState } from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

import styles from "./OrderList.module.css";
import { formatNumberToHrk, formatNumberToEur } from "../utils/formatText";
import {
  OrderStateContext,
  OrderDispatchContext,
} from "../context/order-context";

function OrderList() {
  const dispatch = useContext(OrderDispatchContext);
  const { order } = useContext(OrderStateContext);
  const [totals, setTotals] = useState({});

  const handleRemoveItem = (product) => {
    dispatch({ type: "REMOVE_PRODUCT", payload: { ...product } });
  };

  useEffect(() => {
    const totalNoTax = order.reduce((total, product) => {
      return (total += product.price * product.qty);
    }, 0);
    const tax = totalNoTax * 0.25;
    const totalWithTax = totalNoTax + tax;

    setTotals({ totalNoTax, tax, totalWithTax });
  }, [order]);

  return (
    <>
      <ul className={styles.orderList}>
        {order.length === 0 && (
          <div className={styles.noItems}>
            <p>Još niste dodali proizvod u upit za ponudu.</p>
            <p>
              ← <Link to="/">Nazad na proizvode</Link>
            </p>
          </div>
        )}
        {order.map((product) => {
          return (
            <li key={product.code} className={styles.orderItem}>
              <Img
                className={styles.image}
                alt={product.name}
                fluid={product.image}
              />
              <span className={styles.nameAndDimension}>
                {product.name} ({product.dimension})
              </span>
              <span>{product.qty} kom</span>
              <p>{formatNumberToEur(product.price * product.qty / 7.53450)}</p>
              <button
                type="button"
                className={styles.removeButton}
                onClick={() => handleRemoveItem(product)}
              >
                Obriši
              </button>
            </li>
          );
        })}
      </ul>
      {order.length > 0 && (
        <div className={styles.totalInfo}>
          <p className={styles.label}><>Ukupno (bez PDV-a)</></p>
          <p className={styles.value}>
            {formatNumberToEur(totals.totalNoTax / 7.53450)}
          </p>

          <p className={styles.label}>PDV (25%)</p>
          <p className={styles.value}>
            {formatNumberToEur(totals.tax / 7.53450)}
          </p>

          <p className={styles.label}>Ukupno (s PDV-om)</p>
          <p className={styles.value}>
            {formatNumberToEur(totals.totalWithTax / 7.53450)}
          </p>
          <p className={styles.disclaimer}>
            Ukupan iznos ne uključuje cijenu dostave.
          </p>
        </div>
      )}
    </>
  );
}

export default OrderList;
