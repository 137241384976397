import React from "react";

import Layout from "../components/Layout";
import OrderForm from "../components/OrderForm";
import OrderList from "../components/OrderList";

import { usePageTracking } from "../utils/usePageTracking";

function ShoppingCart() {
  usePageTracking();
  return (
    <Layout location="Upit za ponudu">
      <OrderList />
      <OrderForm />
    </Layout>
  );
}

export default ShoppingCart;
